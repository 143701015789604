@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@300;400;700&display=swap');
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100vw;
  overflow-x: hidden;
  font-family: 'Roboto', serif;
     -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:
}
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
